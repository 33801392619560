(function($) {
  $( document ).ready( function() {

    var ele = $('.glass-box');

    // this function runs every time you are scrolling

    $(window).scroll(function() {

      var top_of_element = ele.offset().top;
      var bottom_of_element = ele.offset().top + ele.outerHeight();
      var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      var top_of_screen = $(window).scrollTop();

      if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
        // the element is visible, do something
        ele.css({
          "transform": "translateX(475px)",
          "transition": "all 4.5s ease"
        });
      } else {
        // the element is not visible, do something else
        ele.css({
          "transform": "translateX(-475px)",
          "transition": "all 4.5s ease"
        });
    }
});

  });
})(jQuery);
